import { collection, deleteField, doc, getFirestore, onSnapshot, setDoc, updateDoc } from '@firebase/firestore';
import { getId, getInstallations, onIdChange } from '@firebase/installations';
import { message } from 'antd';
import publicIp from 'public-ip';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { browserName, browserVersion, osName } from 'react-device-detect';
import { useIntl } from 'react-intl';
import DeviceSessionMonitorDialog from '../components/DeviceSessionMonitorDialog';
import OperationMonitor from '../components/OperationMonitor';
import { ADMIN_OPERATION_REGISTER_DEVICE } from '../constants/admin';
import { OPERATION_STATUS_PENDING } from '../constants/operation';
import { CLIENT_CONSOLE } from '../constants/platform';
import { useAdmin, useIsEBAccess, useIsMockMode, useRealmId } from './AuthenticationContext';
import { usePushToken } from './NotificationContext';

const InstallationContext = React.createContext();

export function InstallationProvider({ children }) {
  const realmId = useRealmId();
  const pushToken = usePushToken();
  const ebAccess = useIsEBAccess();
  const [regiseredDeviceId, setRegiseredDeviceId] = useState();
  const [installId, setInstallId] = useState();
  const [mockExpire, setMockExpire] = useState(null);
  const [registeredDevice, setRegisteredDevice] = useState(null);
  const [ipAddress, setIpAddress] = useState();
  const [operationRef, setOperationRef] = useState();
  const [notice, setNotice] = useState();
  const mockMode = useIsMockMode();
  const admin = useAdmin();
  const intl = useIntl();

  useEffect(() => {
    getId(getInstallations()).then(setInstallId);
    const handler = onIdChange(getInstallations(), setInstallId);
    return handler;
  }, []);

  useEffect(() => {
    publicIp.v4().then(result => setIpAddress(result));
  }, []);

  useEffect(() => {
    if (registeredDevice?.get('notice')) {
      setNotice(registeredDevice?.get('notice'));
      updateDoc(registeredDevice.ref, { notice: deleteField() });
    }
  }, [Boolean(registeredDevice?.get('notice'))]);

  useEffect(() => {
    setMockExpire(null);
    if (realmId && installId && admin && !ebAccess) {
      setOperationRef(operationRunner({
        type: ADMIN_OPERATION_REGISTER_DEVICE,
        clientType: CLIENT_CONSOLE,
        ip: ipAddress || null,
        mockMode,
        clientVersion: process.env.REACT_APP_VERSION,
        deviceName: osName || null,
        os: `${browserName || ''} ${browserVersion || ''}`,
        installId,
        uid: admin.id,
        ...(pushToken && { webToken: pushToken }),
      }));
    }
  }, [realmId, installId, admin?.id, pushToken, ipAddress, mockMode, ebAccess]);

  useEffect(() => {
    setRegisteredDevice(null);
    if (regiseredDeviceId && realmId) {
      const handler = onSnapshot(
        doc(getFirestore(), `realms/${realmId}/devices/${CLIENT_CONSOLE}/installs/${regiseredDeviceId}`),
        setRegisteredDevice
      );
      return handler;
    } else if (regiseredDeviceId && !realmId) {
      setRegiseredDeviceId(null);
    }
  }, [regiseredDeviceId, realmId]);

  const operationRunner = useCallback((params, batch) => {
    const { isPlatform, isBilling, isAdmin, checkStatus, customerId } = params;

    if ((!isPlatform) && !admin) return message.error(intl.formatMessage({ defaultMessage: 'Sytem not ready, please try again' }));

    let operationRef;

    if (isPlatform) {
      operationRef = doc(collection(getFirestore(), `operations`));
    } else if (isBilling) {
      operationRef = doc(collection(getFirestore(), `billings/${realmId}/operations`));
    } else if (checkStatus || (customerId && !isAdmin)) {
      operationRef = doc(collection(getFirestore(), `realms/${realmId}/customers/${customerId}/operations`));
    } else {
      operationRef = doc(collection(getFirestore(), `realms/${realmId}/admins/${admin.id}/operations`));
    }

    const data = {
      ...params,
      status: OPERATION_STATUS_PENDING,
      ...(admin && { operator: admin }),
      client: CLIENT_CONSOLE,
      timestamp: Math.floor(Date.now() / 1000)
    };

    if (batch) {
      batch.set(operationRef, data);
    } else {
      setDoc(operationRef, data);
    }

    return operationRef;
  }, [realmId, admin]);

  const context = useMemo(() => {
    const isStaging = window.location.hostname.indexOf('staging') >= 0;
    const production = process.env.REACT_APP_DEPLOYMENT !== 'dev';

    return {
      installId,
      registeredDevice,
      production,
      mockExpire,
      stripeKey: production ? 'pk_live_4r8PFs7FLw67nZE4piQ2lDSS' : 'pk_test_BLYpxkqZV6sayItu1Vlcii5t',
      stripeClientId: production ? 'ca_HIJCpPQXma8mnqNxf8SPzeyPBHgskKtO' : 'ca_HIJCQf10Qn8ZCDuZQN6G3nhBOMxYRebX',
      posUrl: `https://${production ? (isStaging ? `stagingpos.easybus.app` : `pos.easybizcloud.com`) : `devpos.easybus.app`}`,
      previewConsoleUrl: `https://${production ? (isStaging ? `easybus-staging` : `console-easybus`) : `easybus-dev-console`}.web.app`,
      imageHost: `https://storage.googleapis.com/easybus${production ? `-img` : `-dev-tmp`}`,
      whitelisted: Boolean(registeredDevice && (registeredDevice.get('listed') === true)),
      appVersion: process.env.REACT_APP_VERSION,
      registering: Boolean(operationRef),
      isStaging,
      operationRunner,
      notice,
      closeNotice: () => setNotice(null)
    };
  }, [realmId, installId, registeredDevice, Boolean(operationRef), mockExpire, operationRunner, notice]);

  return (
    <InstallationContext.Provider value={context}>
      {children}
      <OperationMonitor operationRef={operationRef} onComplete={successDoc => {
        setOperationRef(null);
        if (successDoc) {
          setMockExpire(successDoc.get('mockExpire') || null);
          setRegiseredDeviceId(successDoc.get('installId'));
        }
      }} />
      <DeviceSessionMonitorDialog />
    </InstallationContext.Provider>
  );
}

export const useOperator = () => useAdmin();
export const useDeviceId = () => useContext(InstallationContext).installId;
export const useRegisteredDevice = () => useContext(InstallationContext).registeredDevice;
export const useIsProduction = () => useContext(InstallationContext).production;
export const useMockExpire = () => useContext(InstallationContext).mockExpire;
export const useStripeKey = () => useContext(InstallationContext).stripeKey;
export const useStripeClientId = () => useContext(InstallationContext).stripeClientId;
export const usePOSUrl = () => useContext(InstallationContext).posUrl;
export const useEmbedPOSUrl = () => useContext(InstallationContext).embedPOSUrl;
export const usePreviewConsoleUrl = () => useContext(InstallationContext).previewConsoleUrl;
export const useIsDeviceWhitelisted = () => useContext(InstallationContext).whitelisted;
export const useAppVersion = () => useContext(InstallationContext).appVersion;
export const useIsDeviceRegistering = () => useContext(InstallationContext).registering;
export const usePrimaryColor = () => process.env.REACT_APP_PRIMARY_COLOR;
export const useSecondaryColor = () => process.env.REACT_APP_SECONDARY_COLOR;
export const useTertiaryColor = () => process.env.REACT_APP_TERTIARY_COLOR;
export const usePrimaryContainerColor = () => process.env.REACT_APP_PRIMARY_CONTAINER_COLOR;
export const useErrorColor = () => process.env.REACT_APP_ERROR_COLOR;
export const useIsStagingEnv = () => useContext(InstallationContext).isStaging;
export const usePlatformName = () => process.env.REACT_APP_PLATFORM_NAME;
export const usePortalHost = () => process.env.REACT_APP_PORTAL_HOST;
export const useInfoBgColor = () => process.env.REACT_APP_TERTIARY_CONTAINER_COLOR;
export const useWebHostingDomain = () => process.env.REACT_APP_WEBDOMAIN;
export const useHighlightBgColor = () => process.env.REACT_APP_SECONDARY_CONTAINER_COLOR;
export const useSurfaceColor = () => process.env.REACT_APP_SURFACE_VARIANT_COLOR;
export const useMobileDownloadLink = () => process.env.REACT_APP_MOBILE_LINK;
export const useOperation = () => useContext(InstallationContext).operationRunner;
export const useSystemNotice = () => useContext(InstallationContext).notice;
export const useCloseSystemNotice = () => useContext(InstallationContext).closeNotice;