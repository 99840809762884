import { Avatar } from 'antd';
import React from 'react';
import { useRealmId } from '../contexts/AuthenticationContext';
import formatMediaUrl from '../utils/formatMediaUrl';

export default ({ size, service, shape }) => {
  const realmId = useRealmId();
  const photoId = service?.photoId || `${realmId}${service?.id}`;

  return <Avatar size={size} shape={shape} src={formatMediaUrl(photoId, realmId)} />;
}